<!--
 * @Author: your name
 * @Date: 2021-11-22 10:30:24
 * @LastEditTime: 2021-12-15 09:34:47
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \chatgpt-web-client\src\pages\about.vue
-->
<template>
  <div id="about">
    <headerbar leftvisible="true" title="关于我们" />
    <div class="box" style="padding-bottom: 100px">
      <div class="box-body" style="padding-top: 0">
        <div class="logo">
          <img src="@/assets/logo_beltoo.jpg" />
        </div>
        <div class="info">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
  components: {},
  computed: {},
  data() {
    return {
      logo: require("@/assets/logo_beltoo.jpg"),
    };
  },
  methods: {},
  created: function () {},
  mounted: function () {},
};
</script>
<style lang="css" scoped>
@import url("/style/css/common.css");
.logo {
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: 100%;
}
.info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  letter-spacing: 1px;
  line-height: 2;
  text-indent: 26px;
}
.box {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.box-body {
  padding: 20px;
}
.box .box-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
}
.box .box-header .title {
  font-size: 16px;
  font-weight: 700;
  color: #212831;
}
</style>
